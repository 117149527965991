






































































import Vue from "vue";
import Component from "vue-class-component";
import ExhibitorResultCard from "@/components/shared/ExhibitorResultCard.vue";
import ExhibitorLogoCard from "@/components/shared/ExhibitorLogoCard.vue";
import { getModule } from "vuex-module-decorators";
import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";
import Spinners from "@/components/utilities/Spinners.vue";

const tradeshowStore = getModule(tradeshowVuexModule);

@Component({
    components: {
        Spinners,
        ExhibitorResultCard,
        ExhibitorLogoCard
    }
})
export default class SponsorsByTiers extends Vue {
    isLoading = true;

    get titleSponsors() {
        return this.tradeshowCompanies ? this.tradeshowCompanies.title : [];
    }

    get premierSponsors() {
        return this.tradeshowCompanies ? this.tradeshowCompanies.premier : [];
    }

    get partnerSponsors() {
        const companies = this.tradeshowCompanies
            ? this.tradeshowCompanies.partner
            : [];
        return Vue.prototype.MgSortByProperty(companies, "companyName");
        // return this.tradeshowCompanies ? this.tradeshowCompanies.partner : [];
    }

    get tradeshowCompanies() {
        return tradeshowStore.tradeshowCompanies;
    }

    created() {
        tradeshowStore.getTradeshowCompanies().finally(() => {
            this.isLoading = false;
        });
    }
}
